









import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class MarketsDayScanBtn extends Vue {
    @Inject(MarketsServiceS) marketsService!: MarketsService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day | undefined;

    @Prop({
        type: String,
    })
    private provider!: string | null;

    get isScanning() {
        return this.marketsService.scanStatus(this.provider!) === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        return !this.marketsService.isScanAvailable(this.day) || !this.provider;
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        await this.marketsService.triggerScan(this.provider!, this.day);
    }

    get formatScanDate() {
        const dateScan = this.marketsService.finishScanDate(this.provider!);

        if (!dateScan) {
            return '--/--/--';
        }

        return moment(dateScan).format('DD/M/YYYY');
    }
}

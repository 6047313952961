




























































































































import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Year from '@/modules/common/types/year.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsHistoryService, { MarketsHistoryServiceS } from '@/modules/common/modules/markets-history/markets-history.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import Day from '@/modules/common/types/day.type';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import OccupancyExtended from '@/modules/occupancy/components/occupancy-extended.vue';
import OccupancyService, { OccupancyServiceS } from '@/modules/occupancy/occupancy.service';
import MarketsDayScanBtn from '@/modules/markets/components/markets-day-scan-btn.vue';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import formatDate from '@/modules/common/filters/format-date.filter';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import { programLogos } from '@/modules/promotions/consts/logos';
import MarketName from '@/modules/market/components/market-name.vue';
import DayChanger from '../../common/components/day-changer.vue';
import SCAN_STATUS from '../constants/scan-status.constant';
import MarketsDocumentModel from '../models/markets-document.model';
import { MarketData } from '../interfaces/market-data.interface';

interface ITableData {
    hotelName: string,
    position: number,
    myHotel: boolean,
    hotelId: number,
    promoted?: boolean,
    page?: number,
    link?: string,
}

@Component({
    components: {
        ModalWrapper,
        DayChanger,
        PopupEventsContainer,
        OccupancyExtended,
        MarketsDayScanBtn,
        MarketName,
    },
    filters: { PercentFilter, PriceFilter },
})
export default class MarketsCommonPopup extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterMarketsServiceS) private clusterMarketsService!: ClusterMarketsService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(MarketsServiceS) protected marketsService!: MarketsService;
    @Inject(MarketsHistoryServiceS) protected marketsHistoryService!: MarketsHistoryService;
    @Inject(MarketsCommonServiceS) private marketsCommonService!: MarketsCommonService;
    @Inject(OccupancyServiceS) protected occupancyService!: OccupancyService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        default: null,
        type: String,
    })
    private source!: string;

    @Prop({
        default: null,
        type: Object,
    })
    private document!: MarketsDocumentModel;

    @Prop({
        default: null,
        type: String,
    })
    private compsetId!: string;

    @Prop({
        default: null,
        type: Number,
    })
    private hotelId!: number;

    @Prop({
        type: Number,
        required: true,
    })
    private marketId!: number | null;

    @Prop({
        default: false,
        type: Boolean,
    })
    private allowSourcePage!: boolean;

    get isDemo() {
        return this.userService.isDemo;
    }

    get compset() {
        return this.compsetsService.getCompset(this.compsetId);
    }

    get skeleton() {
        return this.isLoading;
    }

    get compsetType() {
        if (!this.compset) {
            return null;
        }
        return this.compset.type;
    }

    get isLoading() {
        if (!this.document || !this.compset) {
            return false;
        }

        if (this.compset) {
            return this.compset.loading.startDate && !this.compset.loading.finishDate;
        }

        return false;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get tableData(): ITableData[] {
        if (this.skeleton) {
            return [...Array(5).keys()].map(item => ({
                hotelName: 'fake hotel',
                position: 1,
                myHotel: false,
                hotelId: item,
                page: 1,
                link: 'link',
            }));
        }
        if (!this.document) return [] as ITableData[];

        const checkinDate = this.marketsCommonService.checkinDate(this.day, this.document);

        if (!checkinDate) {
            return [];
        }

        const addProtocolToLink = (marketData: MarketData) => {
            if (!marketData.link) return marketData;

            const isHaveProtocol = marketData.link.startsWith('http');

            if (!isHaveProtocol) {
                // eslint-disable-next-line no-param-reassign
                marketData.link = `https://${marketData.link}`;
            }

            return marketData;
        };

        const toMarketData = (hotelId: string) => {
            const {
                page, link, position, promoted,
            } = checkinDate[Number(hotelId)];
            return {
                hotelName: this.hotelsService.getHotelName(Number(hotelId)),
                position,
                myHotel: Number(hotelId) === Number(this.hotelId),
                hotelId: Number(hotelId),
                promoted,
                page,
                link,
            } as MarketData;
        };

        const tableData = Object
            .keys(checkinDate)
            .map(toMarketData)
            .map(addProtocolToLink);

        tableData.sort((a, b) => a.position - b.position);

        return tableData;
    }

    get myHotelPosition() {
        return this.tableData.findIndex(item => item.myHotel) + 1;
    }

    get occupancy() {
        const hotelId = this.hotelId || 0;
        const checkinDate = this.marketsCommonService.checkinDate(this.day, this.document);
        const pos = (checkinDate && checkinDate[hotelId]) ? checkinDate[hotelId].position : null;

        if (pos) {
            return this.occupancyService.calculateOccupancyPercent([this.day, hotelId, pos]);
        }

        return this.occupancyService.calculateOccupancyPercent([0]);
    }

    get numberOfHotels() {
        if (!this.hotelId) {
            return 0;
        }
        if (!this.document) {
            return 0;
        }
        return this.marketsCommonService.getNumberOfHotels(this.day, this.hotelId, this.document);
    }

    get isNoData() {
        const isNoData = this.marketsCommonService.isNoData(this.day, this.document);

        return isNoData;
    }

    get isNoVisibility() {
        return this.clusterMarketsService.isNA(this.day, this.compsetId, Number(this.hotelId));
    }

    get isScanning() {
        if (!this.document) { return false; }
        return this.document.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get isScanDisabled() {
        return !this.marketsCommonService.isScanAvailable(this.day);
    }

    get isOutOfRange() {
        return this.marketsCommonService.isOutOfRange(this.document);
    }

    get formatScanDate() {
        const dateScan = this.marketsCommonService.dayUpdateDate(this.day, this.document);

        return formatDate(dateScan ? new Date(dateScan) : null);
    }

    get isAnyPromotion() {
        return !!this.tableData.find(item => (item.promoted === true || item.promoted === false));
    }

    async scan() {
        if (this.isScanning || this.isScanDisabled || !this.source) {
            return;
        }
        await this.marketsService.triggerScan(this.source, this.day);
    }

    myCardColor(day: number) {
        if (this.skeleton || !this.document) {
            return {};
        }
        const color = this.marketsCommonService.getCardAssessment(day as Day, this.hotelId, this.document);

        return {
            high: color === ASSESSMENT_TYPES.GOOD,
            'med-high': color === ASSESSMENT_TYPES.NORMAL,
            'med-low': color === ASSESSMENT_TYPES.FAIR,
            low: color === ASSESSMENT_TYPES.BAD,
        };
    }

    openHistory() {
        const { settings, data } = this.marketsService;
        const source = this.source || settings.provider;

        if (settings && source && data[source]) {
            this.marketsHistoryService.setMarketsData(this.marketsService.data[source], { ...settings, provider: source });
            const marketsHistoryRoute = `${this.$route.path}/markets-history/${this.day}`.replace('//', '/');
            this.$router.push(marketsHistoryRoute);
        }
    }

    get promotionLogo() {
        if (/booking/.test(this.source)) {
            return programLogos.booking.booster;
        }

        if (/expedia/.test(this.source)) {
            return programLogos.expedia.travel_ads;
        }

        return null;
    }
}

import { render, staticRenderFns } from "./markets-common-popup.vue?vue&type=template&id=596fb9f3&scoped=true&"
import script from "./markets-common-popup.vue?vue&type=script&lang=ts&"
export * from "./markets-common-popup.vue?vue&type=script&lang=ts&"
import style0 from "./markets-common-popup.vue?vue&type=style&index=0&id=596fb9f3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596fb9f3",
  null
  
)

export default component.exports